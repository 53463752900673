import React from 'react';
import { navigate } from 'gatsby';
import { availableLeaderboards } from '../../data/promotions';
import Seo from '../../components/seo';
import ComingSoon from '../../components/ComingSoon';
import config from '../../config';

class PromotionsPage extends React.Component {
    componentDidMount = () => {
        if (window) {
            const { pageContext } = this.props;
            if (config.disablePromotions) {
                navigate(pageContext.dynamicDefaultPaths?.defaultPromotionsPath);
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const goToLeaderboard = urlParams.get('tab') === 'leaderboard';

                if (goToLeaderboard) {
                    navigate(availableLeaderboards[0].path);
                } else {
                    navigate(pageContext.dynamicDefaultPaths?.defaultPromotionsPath || '/');
                }
            }
        }
    }

    render = () => <React.Fragment>
        <Seo title="Promotions" />
        {config.disablePromotions && <ComingSoon {...this.props} />}
    </React.Fragment>;
};

export default PromotionsPage;
