import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from "../../layout/default";
import SEO from "../../components/seo";
import Title from '../../components/Title';
import * as s from './s.module.css';
import config from '../../config';

const ComingSoon = ({ location }) => <Layout location={location}>
    <SEO
        title='Promotions'
        description='Virtue Promotions'
    />
    <Helmet>
        <meta key="og:url" property="og:url" content={location.href} />
    </Helmet>
    <div className={s.container}>
        <Title>COMING SOON</Title>
    </div>
</Layout>;

export default ComingSoon;
